.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: #004581;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-weight: bold;
  fill: #004581;
}
