.mobile {
  display: none;
}
.all-tickets > .buttons {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.dropdown {
  width: 180px;
}

@media screen and (max-width: 1023px) {
  .desktop-tickets,
  .reply-form,
  .tickets-container .buttons {
    display: none;
  }
  .mobile {
    display: block;
  }
}
