.profile {
  min-height: 100vh;
  height: 100%;
  background-color: #dde8f0;
}
.profile-container h1 {
  font-size: larger;
}
.profile .spinner-div,
.profile-container {
  width: 80%;
  margin-right: 30px;
  padding-top: 30px;
}

.profile-container .spinner-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-content {
  width: 100%;
}

.profile-form > form > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 20px 0px;
  align-items: center;
}

.profile-container p {
  width: 10%;
  width: 150px;
  font-size: 12px;
}

form {
  width: 100%;
}

.profile-container .profile-form > form [type="text"],
.profile-container .profile-form > form [type="email"] {
  width: 75%;
  padding: 5px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid grey;
}
.profile-container .profile-form > form [type="text"],
.profile-container .profile-form > form [type="email"],
.old-password input,
.new-password input,
.confirm-password input {
  width: 100%;
  padding: 5px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid grey;
  outline: none;
}

.profile-container .profile-form > form [type="text"],
.profile-container .profile-form > form [type="email"]:focus {
  outline: none;
}

.old-password,
.new-password,
.confirm-password {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0px;
}

hr {
  margin: 50px 0px;
}

.update-btn {
  display: flex;
  justify-content: center;
}

.update-button {
  color: white;
  width: 150px;
  padding: 4px 6px;
  height: 40px;
  background-color: #2856c3;
  border-radius: 8px;
  border-style: none;
  margin: 20px 0px;
  cursor: pointer;
}

.save-button {
  display: flex;
  justify-content: center;
}

.save-btn {
  width: 100px;
  padding: 4px 6px;
  height: 40px;
  background-color: bisque;
  border-radius: 8px;
  border-style: none;
  margin-top: 20px;
  cursor: pointer;
}

.update-password {
  margin-top: 100px;
}

@media screen and (max-width: 1300px) {
  .profile-container {
    /* width:80%; */
    min-height: 100vh;
    height: auto;
  }
}

@media screen and (max-width: 1200px) {
  .profile-container {
    /* width:80%; */
    height: auto;
  }
}

@media screen and (max-width: 1000px) {
  .profile-container {
    width: 80%;
    min-height: 100vh;
    height: auto;
  }
}

@media screen and (max-width: 900px) {
  form {
    width: 100%;
  }
  .profile-container h1 {
    font-size: medium;
  }
  .old-password > .profile-container .profile-form > form,
  .new-password > .profile-container .profile-form > form,
  .confirm-password > .profile-container .profile-form > form {
    width: 100%;
    margin-left: 0px;
  }

  .profile-container {
    width: 70%;
    min-height: 100vh;
    height: auto;
  }
  .profile-container p {
    width: 100px;
  }
}

@media screen and (max-width: 600px) {
  form {
    width: 100%;
  }

  .old-password > .profile-container .profile-form > form,
  .new-password > .profile-container .profile-form > form,
  .confirm-password > .profile-container .profile-form > form {
    width: 100%;
    margin-left: 0px;
  }

  .profile-container {
    width: 70%;
    height: auto;
  }

  .profile-container .profile-form > form [type="text"],
  .profile-container .profile-form > form [type="email"] {
    width: 100%;
  }
}

@media screen and (max-width: 400px), (max-width: 500px) {
  form {
    width: 100%;
  }

  .old-password > .profile-container .profile-form > form,
  .new-password > .profile-container .profile-form > form,
  .confirm-password > .profile-container .profile-form > form {
    width: 100%;
    margin-left: 0px;
  }
  .profile-form > form > div {
    flex-direction: column;
    margin: 20px 0px;
    align-items: flex-start;
  }
  .old-password,
  .new-password,
  .confirm-password {
    flex-direction: column;
    margin: 20px 0px;
    align-items: flex-start;
  }
  .profile-container p {
    margin-bottom: 10px;
    width: auto;
  }
  .profile-container {
    width: 100%;
    padding: 0px 20px;
  }

  .profile-container .profile-form > form [type="text"],
  .profile-container .profile-form > form [type="email"] {
    width: 100%;
  }
  .profile-container .profile-form > form [type="text"],
  .profile-container .profile-form > form [type="email"],
  .old-password input,
  .new-password input,
  .confirm-password input {
    width: 100%;
  }
}

@media screen and (max-width: 300px) {
  form {
    width: 100%;
  }

  .profile-container {
    width: 100%;
  }

  .profile-container .profile-form > form [type="text"],
  .profile-container .profile-form > form [type="email"] {
    width: 100%;
  }
}
