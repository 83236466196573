.category {
  display: flex;
  min-height: 100vh;
  height: 100%;
  background-color: #dde8f0;
}
.category .mobile {
  display: none;
}
.category-container {
  width: 80%;
  margin-right: 30px;
  padding-top: 30px;
}
.mobile {
  display: none;
}
.category-container .form-buttons {
  justify-content: flex-end;
  margin-top: 0;
}
.category-container > h1 {
  margin-left: 30px;
  text-align: center;
}
.category-container h2,
.category-container h3 {
  font-size: large;
}
.background-white {
  background-color: #fff;
  padding: 15px 10px;
  margin-top: 20px;
}
.category-table {
  /* padding: 15px 10px; */
  /* margin-top: 30px; */
  border-radius: 10px;
}
.category-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  max-width: 500px;
  height: auto;
  margin: 0 auto;
  margin-top: 50px;
  padding-bottom: 20px;
}
.category-form > .top {
  background-color: #004581;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  color: #fff;
}
.category-form h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
}
.category-form > form {
  width: 100%;
  padding: 20px;
  border-radius: 0px 0 30px 30px;
  height: fit-content;
  background-color: #fff;
}
.category-form > form input {
  display: block;
  width: 100%;
  padding: 10px;
  border: 0;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(128, 117, 117, 0.8);
}
.category-form > form input:focus {
  outline: none;
}

.category-form > form > .password {
  position: relative;
  width: 100%;
}
.category-form > form > .password > .pass-icon {
  position: absolute;
  top: 5px;
  height: 100%;
  right: 20px;
}
.category-form > form > .password > .pass-icon > svg {
  height: 30px;
  width: 30px;
  color: #2856c3;
}
.category-form > form > input[type="submit"] {
  padding: 12px 64px;
  width: 175px;
  height: 48px;
  margin: 0 auto;
  margin-top: 50px;
  margin-top: 25px;
  cursor: pointer;
  background: #2856c3;
  color: white;
  border-radius: 12px;
}

.category > .category-container > div > .form-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.category > .category-container > div > .form-buttons > button {
  position: static;
  width: auto;
  margin-left: 0;
}
.category > .category-container > div > .form-buttons > button:nth-child(2) {
  margin: 0 20px;
}

.category-form > form small {
  color: #e74c3c;
}

.category-form > form > .error {
  margin-top: 25px;
  display: flex;
  align-items: center;
}
.category-form > form > .error > svg {
  margin-right: 10px;
}
.category .dropdown-content {
  width: auto;
}
.category-container h1 {
  font-size: larger;
}
.category .table-container {
  padding-bottom: 100px;
}
.table-container .dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: relative;
  width: auto;
}
.table-container .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  top: 50px;
  right: 0px;
}
/* tbody > tr:last-child .dropdown> .dropdown-content{
    top: -50px;
    right: 0px;
} */
.table-container .dropdown.active > .dropdown-content {
  display: block;
}
.category-container table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  height: max-content;
}

td,
th {
  text-align: center;
  border: 1px solid black;
  border-collapse: collapse;
  border: 1px solid #ddd;
  padding: 8px;
}

thead > tr > th {
  height: 50px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #004581;
  color: white;
}
tbody > tr:nth-child(odd) {
  height: 50px;
}
tbody > tr:hover {
  height: 50px;
  background-color: #ddd;
}
tbody > tr:nth-child(even) {
  height: 50px;
  background-color: #f2f2f2;
}
.category-container .table-container {
  overflow: visible;
}

@media screen and (max-width: 900px) {
  .category-form {
    width: 80%;
  }
  .category-form > form {
    border-radius: 0px 0 20px 20px;
  }
  .category-form > h2 {
    padding: 0 30px;
    font-size: 35px;
  }
  .category-form > form > .password > .pass-icon {
    top: 10px;
  }
  .category-form > form > .password > .pass-icon > svg {
    height: 20px;
    width: 20px;
  }
  .category-container table tr > th:nth-of-type(1),
  .category-container table tr > th:nth-of-type(5),
  .category-container table tr > td:nth-of-type(1),
  .category-container table tr > td:nth-of-type(5) {
    display: block;
  }
}
@media screen and (max-width: 800px) {
  .category > .category-container > div > .form-buttons > button:nth-child(2) {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .form-buttons,
  .category-form,
  .category-table {
    display: none;
  }
  .category .mobile {
    display: block;
  }
  .category-container h1 {
    font-size: medium;
  }
  .background-white {
    background-color: transparent;
    margin-top: 0;
  }
}
@media screen and (max-width: 500px) {
  .category-container {
    margin: 0;
    width: 100%;
    padding: 0 20px;
  }
  .user-container > .users {
    margin-left: 0px;
  }
  .category {
    padding-top: 100px;
  }
  .category-container > h1 {
    margin-left: 0px;
  }
  .category-container .form-buttons {
    justify-content: center;
  }
  .category > .category-container > div > .form-buttons {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .category > .category-container > div > .form-buttons > button {
    margin-left: 0px;
    margin-top: 20px;
  }
  .category-form {
    height: 100%;
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
  }
  .category-form h2 {
    font-size: 14px;
    line-height: 20px;
  }
  .category-form > form {
    width: 100%;
    border-radius: 0;
    padding: 50px 30px;
  }
  .category > .category-container > div > .form-buttons {
    flex-direction: column;
    justify-content: flex-start;
  }
  .category > .category-container > div > .form-buttons > button:last-child {
    margin-top: 0px;
  }
  .category > .category-container > div > .form-buttons > button:nth-child(2) {
    margin: 20px 0px;
  }
}

@media screen and (max-width: 500px) {
  .category-container table tr > th:nth-of-type(1),
  .category-container table tr > th:nth-of-type(5),
  .category-container table tr > th:nth-of-type(3),
  .category-container table tr > td:nth-of-type(1),
  .category-container table tr > td:nth-of-type(5),
  .category-container table tr > td:nth-of-type(3) {
    display: block;
  }
}
@media screen and (max-width: 400px) {
  .category-form > form > input[type="submit"] {
    padding: 12px 20px;
    width: 90%;
    height: 48px;
    border-radius: 8px;
  }
}
