@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.home-page{
  margin-left: 20px;
}
.redirect-button{
    padding: 12px 64px;
    width: 150px;
    cursor: pointer;
    height: 48px;
    margin: 0 auto;
    margin-top: 50px;
    margin-top: 25px;
    background: #2856C3;
    border: 0;
    color: white;
    border-radius: 0;
}


