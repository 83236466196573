.desktop-tickets .table-container {
  padding-top: 30px;
}
.support-ticket .mobile {
  display: none;
}
.tickets {
  display: flex;
  min-height: 100vh;
  height: 100%;
  background-color: #dde8f0;
}
.tickets-container {
  width: 80%;
  padding-right: 30px;
  padding-top: 30px;
}
.support-ticket {
  padding: 30px 10px;
  margin-top: 50px;
  border-radius: 10px;
  background-color: #fff;
}
.tickets-container .buttons {
  display: flex;
  justify-content: flex-end;
}

.reply-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  max-width: 500px;
  height: auto;
  /* margin: 0 auto; */
  padding-bottom: 20px;
  /* overflow-y: auto; */
}
.heading {
  text-align: center;
  padding: 0 50px;
  font-weight: 800;
  margin-bottom: 60px;
  font-size: 48px;
  line-height: 56px;
}
.reply-form > form {
  width: 100%;
  padding: 20px;
  border-radius: 0px 0 30px 30px;
  height: fit-content;
  background-color: #fff;
}

.reply-form > form input,
.reply-form > form > select {
  display: block;
  width: 100%;
  padding-top: 10px;
  border: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(128, 117, 117, 0.8);
}
.reply-form > form input:focus,
.reply-form > form textarea:focus {
  outline: none;
}
/* .reply-form > form label{
    padding-left: 10px;
} */
.relative {
  position: relative;
}
.reply-form .capitalize {
  text-transform: capitalize;
}
.reply-form .upperCase {
  text-transform: uppercase;
}
.reply-form.top-up {
  overflow-y: auto;
}
.reply-form > .top {
  /* margin-top: 100px; */
  background-color: #004581;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  color: #fff;
}
.reply-form h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
}
.reply-form textarea {
  width: 100%;
  padding: 10px;
  resize: vertical;
  border-radius: 5px;
  min-height: 100px;
}
.reply-form textarea[name="complaint"] {
  width: 100%;
  resize: vertical;
  border-radius: 0px;
  min-height: auto;
  padding: 10px 0;
  border: 0;
  color: #000;
  margin-bottom: 10px;
  border-bottom: 2px solid rgba(128, 117, 117, 0.8);
}
.reply-form > form input:focus {
  outline: none;
}

.tickets-container .pop-up::before {
  opacity: 0.8;
  content: "";
  z-index: -1;
  background-color: #8f8f8f;
  position: fixed;
  width: 100vw;
  height: 110vh;
}
.tickets-container .pop-up {
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.reply-form > form > input[type="submit"] {
  padding: 12px 64px;
  width: 257px;
  height: 48px;
  margin: 0 auto;
  margin-top: 50px;
  margin-top: 25px;
  cursor: pointer;
  background: #2856c3;
  color: white;
  border-radius: 12px;
}

.input-div {
  display: flex;
  align-items: center;
}
form > .input-div:last-child {
  margin-bottom: 30px;
}
.input-div > label {
  width: 200px;
}

.reply-form .error {
  margin-bottom: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  position: relative;
}
.tickets-container > h1 {
  font-size: larger;
}
.tickets-container .table-reply {
  display: flex;
  align-items: center;
  height: inherit;
}
.tickets-container .table-reply > p {
  margin-right: 10px;
}
.reply-form > form small {
  color: #e74c3c;
}
.tickets-container .dropbtn {
  position: relative;
  width: 150px;
}
.tickets-container .dropdown-content {
  position: absolute;
  width: 150px;
}
.reply-form > form > div > .error {
  margin-top: 25px;
  display: flex;
  align-items: center;
}
.reply-form > form > div > .error > svg {
  margin-right: 10px;
}

@media screen and (max-width: 1023px) {
  .desktop-tickets,
  .reply-form,
  .tickets-container .buttons {
    display: none;
  }
  .support-ticket .mobile {
    display: block;
  }
  .support-ticket {
    background-color: transparent;
    margin-top: 0;
  }
  .tickets-container > h1 {
    font-size: medium;
  }
}

@media screen and (max-width: 500px) {
  .support-ticket {
    padding: 20px 0;
  }
  .tickets-container {
    width: 100%;
    padding-right: 20px;
    padding-top: 20px;
  }
}
