/* side Navigation */
header {
  width: 20%;
  background-color: #004581;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  height: 100%;
  box-shadow: 3px 0px 5px 1px rgba(0, 0, 0, 0.1);
}

.top {
  width: 100%;
  color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid black;
}
.logo {
  width: 50%;
}
.logo > a {
  width: 100%;
}
.logo img {
  width: 100px;
  height: 100px;
}
.term {
  color: aqua;
}

header > .top > .hamburger-menu {
  display: none;
}
.profile_image > img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

nav a {
  color: #dde8f0;
  display: flex;
  padding-left: 50px;
  align-items: center;
  text-decoration: none;
}
nav a.active {
  border-left: 5px solid red;
}
nav li {
  margin-left: 10px;
  list-style-type: none;
}

nav a > li,
nav p > span {
  color: #e5edee;
  padding: 20px 20px;
  cursor: pointer;
}
nav p {
  display: flex;
  align-items: center;
  color: #e5edee;
  cursor: pointer;
  padding-left: 50px;
  width: 100%;
}
nav p > svg {
  margin-right: 10px;
}

.home-container,
.tickets-container,
.user-container,
.category-container,
.profile-container,
.spinner-div {
  margin-left: 20%;
  padding-left: 30px;
  padding-right: 30px;
}
progress {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}
header > .brand {
  width: 100%;
  /* text-align: center; */
  height: 30px;
  color: rgb(244, 224, 224);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
  position: absolute;
  bottom: 0;
}
@media screen and (max-width: 900px) {
  header {
    width: 30%;
  }
  .home-container,
  .tickets-container,
  .user-container,
  .category-container,
  .profile-container,
  .spinner-div {
    margin-left: 30%;
    padding-left: 20px;
  }
  .icon {
    height: 30px;
    width: 30px;
  }
  .links a {
    padding: 10px 10px;
    font-size: 12px;
    line-height: 20px;
  }
}

@media screen and (max-width: 700px) {
  header > .brand {
    position: static;
  }
  :root {
    /* --primary: rebeccapurple; */
    --primary: #0d1d2f;
    --secondary: #fff;
  }

  /* Firefox */
  .header {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
  }

  /* Chrome, Edge, and Safari */
  .header::-webkit-scrollbar {
    width: 15px;
  }

  .header::-webkit-scrollbar-track {
    background: var(--primary);
    border-radius: 5px;
  }

  .header::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 14px;
    border: 3px solid var(--primary);
  }
}
@media screen and (max-width: 500px) {
  header {
    width: 100%;
    height: fit-content;
  }
  .top {
    justify-content: space-between;
  }
  .logo {
    width: 50%;
  }
  .logo > a {
    width: 100%;
  }
  .logo img {
    width: 50px;
    height: 50px;
  }
  .home-container,
  .tickets-container,
  .user-container,
  .category-container,
  .profile-container,
  .spinner-div {
    margin-left: 0px;
    margin: 0 auto;
  }
  .dashboard,
  .tickets,
  .more,
  .profile {
    padding-top: 100px;
  }
  header.active {
    width: 100%;
  }
  header.active > .top {
    padding: 0 20px;
  }
  header > nav {
    display: none;
  }
  header.active > nav {
    display: block;
    padding-bottom: 20px;
    margin-bottom: 50px;
  }
  header > .top > .hamburger-menu {
    display: block;
  }
  header.active > .top {
    margin: 20px 0;
  }
  header > .brand {
    display: none;
  }
  header.active > .brand {
    display: block;
  }
}
