.user {
  display: flex;
  min-height: 100vh;
  height: 100%;
  background-color: #dde8f0;
}
.user .mobile {
  display: none;
}
.user-container > h1 {
  font-size: larger;
}
.user-container {
  width: 80%;
  margin-right: 30px;
  padding-top: 30px;
}

.user-container > h1 {
  margin-left: 30px;
  text-align: center;
}
.user-container h2,
.user-container h3 {
  margin-bottom: 10px;
  font-size: large;
}
.user-container > .users {
  padding: 30px 10px;
  margin-left: 30px;
  margin-top: 50px;
  background-color: #fff;
  border-radius: 10px;
}

.user-container > .users > .loading {
  display: flex;
  justify-content: center;
}

.user-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  max-width: 500px;
  height: auto;
  margin: 0 auto;
  padding-bottom: 20px;
}
.dropbtn > p,
.dropdown-content > p {
  width: 100%;
  font-size: 13px;
}
.user .dropdown {
  position: relative;
}
.user .dropdown-content {
  position: absolute;
}
.pop-up::before {
  opacity: 0.8;
  content: "";
  z-index: -1;
  /* background-color: #000; */
  background-color: #8f8f8f;
  position: fixed;
  width: 100vw;
  height: 110vh;
}
.pop-up {
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.user-form > .top {
  background-color: #004581;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  color: #fff;
}
.user-form h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
}
.user-form > form {
  width: 100%;
  padding: 20px;
  border-radius: 0px 0 30px 30px;
  height: fit-content;
  background-color: #fff;
}
.user-form > form input,
.user-form > form > select {
  display: block;
  width: 100%;
  padding: 10px;
  border: 0;
  margin-bottom: 30px;
  outline: none;
  border-bottom: 1px solid rgba(128, 117, 117, 0.8);
}
.user-form > form input:focus {
  outline: none;
}

.user-form > form > .password {
  position: relative;
  width: 100%;
}
.user-form > form > .password > .pass-icon {
  position: absolute;
  top: 5px;
  height: 100%;
  right: 20px;
}
.user-form > form > .password > .pass-icon > svg {
  height: 30px;
  width: 30px;
  color: #2856c3;
}
.user-form > form > input[type="submit"] {
  padding: 12px 64px;
  width: 175px;
  height: 48px;
  margin: 0 auto;
  margin-top: 50px;
  margin-top: 25px;
  cursor: pointer;
  background: #2856c3;
  color: white;
  border-radius: 12px;
}

.form-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.form-buttons > button {
  position: static;
  width: auto;
  margin-left: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}

.user-form > form small {
  color: #e74c3c;
}

.user-form > form > .error {
  margin-top: 25px;
  display: flex;
  align-items: center;
}
.user-form > form > .error > svg {
  margin-right: 10px;
}
/* .user-form > form > select{
    width: 100%;
    height: 30px;
} */

.user-form > form > select > option {
  width: 100%;
}

@media screen and (max-width: 900px) {
  .user-form {
    width: 80%;
  }
  .home-container > .users {
    padding: 20px 0px;
    margin-left: 20px;
  }
  .user-container {
    width: 70%;
    padding-right: 20px;
  }
  .user-form > form {
    border-radius: 0px 0 20px 20px;
  }
  .user-form > h2 {
    padding: 0 30px;
    font-size: 35px;
  }
  .user-form > form > .password > .pass-icon {
    top: 10px;
  }
  .user-form > form > .password > .pass-icon > svg {
    height: 20px;
    width: 20px;
  }
  /* @media screen and (max-width: 900px) */
  .table-container {
    padding: 0;
  }
}
@media screen and (max-width: 800px) {
  .form-buttons > * {
    margin-bottom: 20px;
  }
  .form-buttons > button:last-child {
    margin-left: 0px;
  }
  .user-container table tr > th:nth-of-type(3),
  .user-container table tr > th:nth-of-type(4),
  .user-container table tr > td:nth-of-type(3),
  .user-container table tr > td:nth-of-type(4) {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .users,
  .user-form,
  .user-container .form-buttons {
    display: none;
  }
  .user .mobile {
    display: block;
  }
  .support-ticket {
    background-color: transparent;
    margin-top: 0;
  }
  .user-container > h1 {
    font-size: large;
  }
}

@media screen and (max-width: 500px) {
  .user {
    padding: 0;
    padding-top: 80px;
    /* margin-top: 200px; */
  }
  .user-container {
    width: 100%;
    padding-right: 20px;
    padding-top: 20px;
    margin-right: 0;
  }
  .user-container > h1 {
    text-align: left;
    margin-left: 0;
  }
}
