.overlay {
  position: absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 10; /* Specify a stack order in case you're using a different order for other elements */
}

.form > form a {
  text-decoration: none;
  color: inherit;
}

/* .form > form input{
    display: block;
    width: 100%;
    padding: 10px;
    border: 0;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(128, 117, 117, 0.8);
} */
.form > form input:focus {
  outline: none;
}

.form > form > .password {
  position: relative;
}
.form > form > .password > .pass-icon {
  position: absolute;
  top: 5px;
  height: 100%;
  right: 20px;
}
.form > form > .password > .pass-icon > svg {
  height: 30px;
  width: 30px;
  color: #2856c3;
}
.form > form > input[type="submit"] {
  padding: 12px 64px;
  width: 257px;
  height: 48px;
  margin: 0 auto;
  margin-top: 50px;
  margin-top: 25px;
  cursor: pointer;
  /* background: #4dc6f3; */
  background: #2856c3;
  color: white;
  border-radius: 12px;
}

.form > form > .consent {
  width: 80%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form > form > .consent > input[type="checkbox"] {
  width: auto;
  margin-bottom: 0;
  margin: 5px 0;
  margin-right: 5px;
}
.email,
.password,
.error {
  margin-bottom: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  position: relative;
}
.form > form small {
  color: #e74c3c;
}

.form > form > .error {
  margin-top: 25px;
  display: flex;
  align-items: center;
}
.form > form .login-error {
  margin-top: 10px;
}
.form > form .login-error > svg {
  margin-right: 10px;
}
.form > form > .error > svg {
  margin-right: 10px;
}

@media screen and (max-width: 900px) {
  .form {
    width: 60%;
    border-radius: 30px 0 0 30px;
    margin: 0 auto;
  }
  .form > h2 {
    padding: 0 30px;
    font-size: 35px;
  }
  .form > form .alternates > .google,
  .form > form .alternates > .facebook {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
  }
  .form > form > .password > .pass-icon {
    top: 10px;
  }
  .form > form > .password > .pass-icon > svg {
    height: 20px;
    width: 20px;
  }
}
/* 
@media screen and (max-width: 769px) {
   h5 {
    font-size: 16px !important;
    text-align: center;
    padding: 1.5rem 2rem;
    line-height: 2 !important;
  }
}

@media screen and (max-width: 500px) {
  .form > h2 {
    padding: 0 10px;
    line-height: 40px;
    margin-bottom: 40px;
    font-size: 24px;
  }
  .form > form {
    width: 100%;
    border-radius: 0;
  }
  .form > form .alternates p {
    font-size: 14px;
  }
  .form > form .consent {
    width: 100%;
  }
} */
